import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { lazy } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ToolTip from '../ToolTip';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const PercentageBarGraph = lazy(() =>
  import(`@/components/UI/Graph/PercentageBarGraph/${layout}`)
);
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const graphGenerator = ({ title, value, total, percentage, info ="infoBox" }) => {
    return (
    <Styled.Items>
        <Styled.Title>
            <Label variant="body1" className="title">{title}
            <ToolTip title={info} arrow placement='top'>
                <InfoOutlinedIcon /></ToolTip></Label>
            <Label variant="body1" className="title">{`${value}/${total}`}</Label>
        </Styled.Title>
        <PercentageBarGraph isFlexed percentage={`${percentage}%`} smallSize />
    </Styled.Items>
    )
}

const FleetScore = ({scoreCard}) => {
    const { t } = useTranslation();
    return (
        <Styled.Wrapper>
            {graphGenerator({
                title: t('scorecard.driverBehavior'),
                value: scoreCard?.aggregatedDrivingBehaviorScore,
                total: '60',
                percentage: scoreCard?.aggregatedDrivingBehaviorScore * 1.665,
                info: (
                    <p><Trans i18nKey='scorecard.driverBehaviorDescription' /></p>
                )
            })}
            {graphGenerator({
                title: t('scorecard.unpoweredDays'),
                value: scoreCard?.aggregatedUnpoweredDaysScore,
                total: '20',
                percentage: scoreCard?.aggregatedUnpoweredDaysScore * 5,
                info: (
                    <p><Trans i18nKey='scorecard.unpoweredDaysDescription' /></p>
                )
            })}
            {graphGenerator({
                title: t('scorecard.radiusViolations'),
                value: scoreCard?.aggregatedRadiusViolationsScore,
                total: '20',
                percentage: scoreCard?.aggregatedRadiusViolationsScore * 5,
                info: (
                    <p><Trans i18nKey='scorecard.radiusViolationsDesc' /></p>
                )
            })}
        </Styled.Wrapper>
    );
}

export default FleetScore;
