import {
  COOKIE_AUTH_DETAILS,
  pageUrls
} from '@/config/constants/keys';
import { getCookie, setCookie } from '@/services/cookie';

const initialState = {
  data: {},
  states: [],
  updateCardDetails: '',
  allCycleExceptions: [],
  allCycleRules: [],
  allVehicleTypes: []
};

export const companyData = {
  state: { ...initialState },
  reducers: {
    setSuccess(state, payload) {
      return {
        ...state,
        data: { ...payload },
        states: [...payload.states],
        allCycleExceptions: payload.allCycleExceptions
          ? [...payload.allCycleExceptions]
          : [],
        allCycleRules: payload.allCycleRules ? [...payload.allCycleRules] : [],
        allVehicleTypes: payload.allVehicleTypes
          ? [...payload.allVehicleTypes]
          : []
      };
    },
    setFailed() {
      return { ...initialState };
    },
    setSaveSuccess(state, payload) {
      return {
        // ...state,
        // data: { ...payload },
        // states: [...state.states],
        // allCycleExceptions: [...state.allCycleExceptions],
        // allCycleRules: [...state.allCycleRules],
        // allVehicleTypes: [...state.allVehicleTypes]
      };
    },
    setUpdateCardLink(state, payload) {
      return {
        // ...state,
        // updateCardDetails: payload
      };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getCompanyDataApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.setSuccess(response);
      } catch (error) {
        this.setFailed();
      }
    },
    async saveData(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveCompanyDataApi');
        const refreshApiObject = getApi.default('refreshApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: apiObject
          },
          id: payload.apiId
        });
        // response && this.setSaveSuccess(response);
        if (response) {
          this.fetchData({
            id: 'getCompanyInfo',
            data: {
              error: ''
            }
          });
          const refreshResponse = await dispatch.asyncRequests.handleAsyncRequests(
            {
              payload: {
                // data: {
                //   refreshToken: getCookie(COOKIE_AUTH_DETAILS)?.refreshToken
                // },
                api: {
                  ...refreshApiObject,
                  url: `${refreshApiObject.url}?refreshToken=${
                    getCookie(COOKIE_AUTH_DETAILS)?.refreshToken
                  }`
                }
              },
              id: payload.apiId
            }
          );
          if (refreshResponse) {
            setCookie(COOKIE_AUTH_DETAILS, refreshResponse);
            window.location.reload();
          }
        }
      } catch (error) {
        payload.callback('error', error.message, payload);
        // this.setFailed();
      }
    },
    async cancelSubscription(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('cancelSubscriptionApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            data: { ...payload.data },
            api: apiObject
          },
          id: payload.apiId,
          callback: payload.callback
        });
        payload.callback('success', response);
        // response && this.setSaveSuccess(response);
      } catch (error) {
        payload.callback('error', error.message);
        // this.setFailed();
      }
    },
    async updateCard(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('updateCardApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        if (response) {
          window.location = response;
        }
      } catch (error) {
        payload.callback('error', error.message);
        // this.setFailed();
      }
    },
    async updateCardDetails(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('updateCardDetailsApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        // 'Card Details Updated Successfully'
        payload.callback &&
          payload.callback(
            'success',
            payload.i18n.t('common.cardDetails'),
            () => payload.history && payload.history.push(pageUrls?.dashboard)
          );
      } catch (error) {
        payload.callback('error', error.message);
        // this.setFailed();
      }
    },
    async updateSuspendedCard(payload) {
      try {
        const getApi = await import(
          `@/config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('updateSuspendedCardApi');

        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });

        // 'Card Details Updated Successfully'
        payload.callback &&
          payload.callback(
            'success',
            payload.i18n.t('common.cardDetails'),
            () => {
              let tokenVal = getCookie(COOKIE_AUTH_DETAILS);
              tokenVal.previousPaymentFailed = false;
              setCookie(COOKIE_AUTH_DETAILS, tokenVal, tokenVal.rememberMe);
              payload.history && payload.history.push(pageUrls.dashboard);
            }
          );
        // payload.callback && payload.callback('success', payload.i18n.t('common.cardDetails'), () => {
        //   deleteCookie(COOKIE_CLIENT_AUTH_DETAILS);
        //   deleteCookie(COOKIE_AUTH_DETAILS);
        //   dispatch({ type: 'RESET_APP' });
        //   window.location.reload();
        // })
      } catch (error) {
        payload.callback('error', error.message);
        // this.setFailed();
      }
    }
  })
};
