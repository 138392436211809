import PropTypes from 'prop-types';
import React, { lazy, useRef, useState } from 'react';

import {
  COOKIE_AUTH_DETAILS,
  ROLES,
  imageUrls,
  pageUrls
} from '@/config/constants/keys';
import { getCookie } from '@/services/cookie';
import {
  getFilterLocaleValues,
  getImagePath,
  hasEnabledFeature,
  hasRestrictedRole,
  hasValue,
  isInsuracePortalUser,
  isdvirOnly,
  objectArrayFromSameField,
  phoneNumberValidation
} from '@/utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from 'formik';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import underscore from 'underscore';

const layout = window.$environment?.CURRENT_LAYOUT;
const serviceProvider = window.$environment?.SERVICE_PROVIDER;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const CheckBoxToggle = lazy(() => import(`@/components/UI/Checkbox/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);
const Link = lazy(() => import(`@/components/UI/Link`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const Payment = lazy(() => import(`@/components/Payment/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps: {
    i18n,
    Styled,
    history,
    hasSaveApiStatus,
    states,
    webfleetProviders,
    cancelSubscription,
    apiStatus,
    callback,
    allCycleRules,
    allCycleExceptions,
    allVehicleTypes,
    updateCard,
    updateCardDetails,
    eventTypes,
  },
  submitAction
}) => {
  const [open, setOpen] = useState(false);
  const [cModal, setCModel] = useState(false);
  const [cModalData, setCModalData] = useState({});
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {
      return (
        <div className="deleteParent">
          <div className="imageSection">
            <img src={getImagePath(imageUrls.confirmIcon)} alt="Are you sure" />
          </div>
          <h2>You are going to cancel your subscription!</h2>
          <div className="deletePopupBtn">
            <div className="cancelBtn">
              <Button
                label={i18n.t('common.cancel')}
                type={'reset'}
                onClick={() => {
                  setOpen(false);
                }}
              ></Button>
            </div>
            <div className="deletebtn">
              <Button
                label={i18n.t('common.ok')}
                onClick={() => {
                  cancelSubscription({
                    apiId,
                    callback: callback
                  });
                  setOpen(false);
                }}
              ></Button>
            </div>
          </div>
        </div>
      );
    }
  });
  const apiId = 'cancelSubscription';
  const paymentRef = useRef();
  const hasCancelSubscriptionApiStatus = hasValue(
    apiStatus.status,
    'api',
    apiId
  );
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const isDvirOnlyFlagEnabled = isdvirOnly()
  const isWebfleet = window?.$environment?.SERVICE_PROVIDER === 'webfleet';
  const maintArray = [1, 2, 3, 4, 5, 6, 7];
  const maintPeriod = objectArrayFromSameField(maintArray);
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  const dotUpdation = (value) => {
    return value.substring(0, 9);
  };
  const cardDetails = (data) => {
    let obj = {
      cardHolderName: data.cardHolderName,
      stripeToken: data.stripeToken
    };
    let apiId = 'updateCardDetails';
    updateCardDetails({
      apiId,
      data: obj,
      callback: callback,
      history: history,
      i18n: i18n
    });
    setOpen(false);
    // handleEdit(data?.stripeToken, { field: 'nonce', type: 'text' });
  };

  // CLUTCH ONLY
  const featureEnable = (featureName) => ({
    header: '',
    content: () => (
      <div className="deleteParent">
          <h2 style={{ textAlign: "center" }} >{featureName} enabled</h2>
          <div className="deletePopupBtn">
            <div className="cancelBtn"/>
            <div className="deletebtn">
              <Button
                label={i18n.t('common.ok')}
                onClick={() => {
                  setCModel(false);
                  setCModalData({});
                }}
              ></Button>
            </div>
          </div>
    </div>
    )
  })

  // CLUTCH ONLY
  const featureDisable = (featureName) => ({
    header: '',
    content: () => (
      <div className="deleteParent">
          <h2  style={{ textAlign: "center" }}>{featureName} disabled</h2>
          <div className="deletePopupBtn">
            <div className="cancelBtn"/>
            <div className="deletebtn">
              <Button
                label={i18n.t('common.ok')}
                onClick={() => {
                  setCModel(false);
                  setCModalData({});
                }}
              ></Button>
            </div>
          </div>
    </div>
    )
  })

  return (
    <Styled.Wrapper>
      <div className="section-wrapper">
        <SubHeader
          text={i18n.t('common.mainInformation')}
          className="subheaderSection companyTitle"
          variant="h2"
        />
        {/* {hasSaveApiStatus?.errorMessage && (
          <Styled.CompanyError>
            <Message type="error" message={hasSaveApiStatus.errorMessage} />
          </Styled.CompanyError>
        )} */}
        <Styled.wrapperSection>
        <Styled.leftSection>
          <div className="companyForm firstRow">
            <FormStyle.FieldsWrapper>
              <Label htmlFor="company" variant="body1">
                {i18n.t('common.company')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="company"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'company' })
                  }
                  as={Input}
                  placeholder={i18n.t('companyData.companyName')}
                  value={values['company']}
                  type="text"
                  className="borderStyle"
                  id="company"
                  onLoadFocus
                  disabled={isWebfleet}
                />
                {showError(
                  'company',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="dotNumber" variant="body1">
                {i18n.t('common.dotNumber')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="dotNumber"
                  id="dotNumber"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'dotNumber' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.dotNumber')}
                  value={values['dotNumber']}
                  type="text"
                  updateValue={(e) => dotUpdation(e)}
                  disabled={isWebfleet}
                />
                {showError(
                  'dotNumber',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            {layout !== 'Clutch' && (
              <>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="maintenancePeriod" variant="body1">
                    {i18n.t('companyData.maintainancePeriodMonth')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="maintenancePeriod"
                      id="maintenancePeriod"
                      onChange={(event) =>
                        handleEdit(event, { field: 'maintenancePeriod' })
                      }
                      as={Select}
                      placeholderText={i18n.t('companyData.maintainancePeriod')}
                      value={values['maintenancePeriod']}
                      suggestions={maintPeriod}
                    />
                    {showError(
                      'maintenancePeriod',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="maintenanceMileage" variant="body1">
                    {i18n.t('companyData.maintainanceMileage')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="maintenanceMileage"
                      id="maintenanceMileage"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'maintenanceMileage' })
                      }
                      as={Input}
                      placeholder={i18n.t('companyData.maintainanceMileage')}
                      value={values['maintenanceMileage']}
                      type="text"
                      className="borderStyle"
                    />
                    {showError(
                      'maintenanceMileage',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <FormStyle.TextWrapper className="subscriptionCompany">
                    <Link
                      id="subscriptionTier"
                      newPage
                      href={pageUrls.subscriptionLink}
                    >
                      {i18n.t('registration.subscriptionTier')}
                    </Link>
                  </FormStyle.TextWrapper>
                  <FormStyle.CheckBoxWrapper direction="row">
                    <RadioButton
                      disabled
                      aria-labelledby="subscriptionTier"
                      value={values.subscriptionTier}
                      options={
                        window.$environment.SERVICE_PROVIDER === 'em'
                          ? [
                              { label: 'Fleet Xpress', value: 'STANDARD' },
                              { label: 'Xtreme', value: 'PRO' }
                            ]
                          : window.$environment.SERVICE_PROVIDER === 'tp'
                          ? [
                              { label: 'Professional', value: 'LITE' },
                              { label: 'Deluxe', value: 'STANDARD' },
                              { label: 'Fleet', value: 'PRO' }
                            ]
                          : window.$environment.SERVICE_PROVIDER === 'gs'
                          ? [
                            { label: 'Prime8 ELD', value: 'LITE' },
                            { label: 'Fleet Xpress', value: 'STANDARD' },
                            { label: 'Xtreme', value: 'PRO' },
                            // { label: 'Xtreme plus camera', value: 'PRO_PLUS_CAMERA' }
                          ]
                          : [
                              { label: 'Prime8 ELD', value: 'LITE' },
                              { label: 'Fleet Xpress', value: 'STANDARD' },
                              { label: 'Xtreme', value: 'PRO' }
                            ]
                      }
                      // onClick={(event) => {
                      //   handleEdit(event, {
                      //     field: 'subscriptionTier',
                      //     type: 'radio'
                      //   });
                      // }}
                    />
                  </FormStyle.CheckBoxWrapper>
                </FormStyle.FieldsWrapper>
              </>
            )}
          </div>
          {!token?.roles?.includes(ROLES.SERVICE_MANAGER) &&  (
          <div className="companyForm">
            {window.$environment.SERVICE_PROVIDER !== 'clutch' && window.$environment.SERVICE_PROVIDER !== 'webfleet' && window.$environment.SERVICE_PROVIDER !== 'riskTheory' && (
              <>
                <FormStyle.FieldsWrapper className="valueWrapper">
                  <Label variant="body1">
                    {i18n.t('companyData.contractExpirationDate')}
                  </Label>
                  <FormStyle.TextWrapper className="values">
                    {values.contractExpirationDate}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper className="valueWrapper">
                  <Label variant="body1">
                    {i18n.t('companyData.currentSubscriptionCost')}
                  </Label>
                  <FormStyle.TextWrapper className="values">
                    {values.currentSubscriptionCost}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
              </>
            )}

            <FormStyle.FieldsWrapper className="valueWrapper">
              <Label variant="body1">{i18n.t('companyData.noOfTrucks')}</Label>
              <FormStyle.TextWrapper className="values">
                {values.initialTrucks}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper className="valueWrapper">
              <Label variant="body1">
                {i18n.t('companyData.homeTimeZone')}
              </Label>
              <FormStyle.TextWrapper className="values">
                {values.timeZone}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <div className="checkboxesCompany oldviewAlign">
              {token?.roles?.includes('FLMANAGER') && serviceProvider !== 'webfleet' && (
                <FormStyle.FieldsWrapper
                  htmlFor="skipVehicleFilter"
                  className="agreeTerms"
                >
                  <FormStyle.TextWrapper>
                    <CheckBoxToggle
                      aria-labelledby="agree"
                      type="NormalGrey"
                      checked={values.skipVehicleFilter}
                      label={i18n.t('companyData.skipcompanyCheckbox')}
                      onClick={(event) =>
                        handleEdit(event, {
                          field: 'skipVehicleFilter'
                        })
                      }
                      name="skipVehicleFilter"
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
              )}
              {token?.roles?.includes('FLMANAGER') &&
                hasEnabledFeature('editOilFieldWaitingEnabled') && !isDvirOnlyFlagEnabled && (
                  <FormStyle.FieldsWrapper
                    htmlFor="showOw"
                    className="agreeTerms"
                  >
                    <FormStyle.TextWrapper>
                      <CheckBoxToggle
                        aria-labelledby="agree"
                        type="NormalGrey"
                        checked={values.showOw}
                        label={i18n.t('companyData.enablecompanyCheckbox')}
                        onClick={(event) =>
                          handleEdit(event, {
                            field: 'showOw'
                          })
                        }
                        name="showOw"
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>
                )}
            </div>
          </div>)
          }
        </Styled.leftSection>
        <Styled.rightSection>
          <div className="companyForm">
            <FormStyle.FieldsWrapper>
              <Label htmlFor="address" variant="body1">
                {i18n.t('common.address')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="address"
                  id="address"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'address' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.address')}
                  value={values['address']}
                  type="text"
                  disabled={isWebfleet}
                />
                {showError(
                  'address',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="city" variant="body1">
                {i18n.t('common.city')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="city"
                  id="city"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'city' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.city')}
                  value={values['city']}
                  type="text"
                  disabled={isWebfleet}
                />
                {showError(
                  'city',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            <FormStyle.FieldsWrapper>
              <Label htmlFor="state" variant="body1">
                {i18n.t('common.state')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="state"
                  id="state"
                  onChange={(event) => {
                    handleEdit(event, { field: 'state' });
                  }}
                  as={Select}
                  placeholderText={i18n.t('common.state')}
                  value={values['state']}
                  suggestions={states}
                  disabled={isWebfleet}
                />
                {showError(
                  'state',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="zipCode" variant="body1">
                {i18n.t('common.zipCode')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="zipCode"
                  id="zipCode"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'zipCode' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.zipCode')}
                  value={values['zipCode']}
                  type="text"
                />
                {showError(
                  'zipCode',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="companyPhone" variant="body1">
                {i18n.t('common.phone')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="companyPhone"
                  id="companyPhone"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'companyPhone' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.phone')}
                  value={values['companyPhone']}
                  type="text"
                  updateValue={phoneNumberValidation}
                />
                {showError(
                  'companyPhone',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            {serviceProvider === 'webfleet' && <FormStyle.FieldsWrapper>
              <Label htmlFor="email" variant="body1">
                {i18n.t('common.email')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="email"
                  id="email"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'email' })
                  }
                  as={Input}
                  disabled
                  placeholder={i18n.t('common.email')}
                  value={values['email']}
                  type="text"
                />
                <div className="helperText">{i18n.t('Email used to send Missing Information')}</div>
                {showError(
                  'email',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>}
            {serviceProvider === 'webfleet' ? 
            <> <FormStyle.FieldsWrapper>
              <Label htmlFor="webfleetProvider" variant="body1">
                {i18n.t('webfleetProvider')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="webfleetProvider"
                  id="webfleetProvider"
                  onChange={(event) => {
                    handleEdit(event, { field: 'webfleetProvider' });
                  }}
                  as={Select}
                  placeholderText={i18n.t('webfleetProvider')}
                  value={values['webfleetProvider']}
                  suggestions={webfleetProviders}
                  disabled={isWebfleet}
                />
                {showError(
                  'webfleetProvider',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper> 

            <FormStyle.FieldsWrapper>
              <Label htmlFor="subscriptionTier" variant="body1">
                {i18n.t('registration.subscriptionTier')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="subscriptionTier"
                  id="subscriptionTier"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'subscriptionTier' })
                  }
                  as={Input}
                  disabled
                  placeholder={i18n.t('registration.subscriptionTier')}
                  value={values['subscriptionTier']}
                  type="text"
                />
                {showError(
                  'subscriptionTier',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            </>: null}
          </div>
          {!token?.roles?.includes(ROLES.SERVICE_MANAGER) &&  (
          <div className="companyForm">
            <FormStyle.FieldsWrapper>
              <Label htmlFor="contactPerson" variant="body1">
                {i18n.t('companyData.contactPerson')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="contactPerson"
                  id="contactPerson"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'contactPerson' })
                  }
                  as={Input}
                  placeholder={i18n.t('companyData.contactPerson')}
                  value={values['contactPerson']}
                  type="text"
                />
                {showError(
                  'contactPerson',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            <FormStyle.FieldsWrapper>
              <Label htmlFor="otherLocationContactPerson" variant="body1">
                {i18n.t('companyData.otherLocationContactPerson')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="otherLocationContactPerson"
                  id="otherLocationContactPerson"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'otherLocationContactPerson' })
                  }
                  as={Input}
                  placeholder={i18n.t('companyData.otherLocationContactPerson')}
                  value={values['otherLocationContactPerson']}
                  type="text"
                />
                {showError(
                  'otherLocationContactPerson',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            <FormStyle.FieldsWrapper>
              <Label htmlFor="otherLocationAddress" variant="body1">
                {i18n.t('companyData.otherLocationAddress')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="otherLocationAddress"
                  id="otherLocationAddress"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'otherLocationAddress' })
                  }
                  as={Input}
                  placeholder={i18n.t('companyData.otherLocationAddress')}
                  value={values['otherLocationAddress']}
                  type="text"
                />
                {showError(
                  'otherLocationAddress',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            <FormStyle.FieldsWrapper>
              <Label htmlFor="otherLocationPhone" variant="body1">
                {i18n.t('companyData.otherLocationPhoneNumber')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="otherLocationPhone"
                  id="otherLocationPhone"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'otherLocationPhone' })
                  }
                  as={Input}
                  updateValue={phoneNumberValidation}
                  placeholder={i18n.t('companyData.otherLocationPhoneNumber')}
                  value={values['otherLocationPhone']}
                  type="text"
                />
                {showError(
                  'otherLocationPhone',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>

            <FormStyle.FieldsWrapper>
              <Label htmlFor="website" variant="body1">
                {i18n.t('companyData.website')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="website"
                  id="website"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'website' })
                  }
                  as={Input}
                  placeholder={i18n.t('companyData.website')}
                  value={values['website']}
                  type="text"
                />
                {showError(
                  'website',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
             {serviceProvider === 'clutch' &&
              <div>
                  <SubHeader
                    text={i18n.t('companyData.advanceSettings')}
                    className="subheaderSection companyTitle"
                    variant="h2"
                  />
                  <FormStyle.FieldsWrapper
                    htmlFor="skipVehicleFilter"
                    className="agreeTerms"
                  >
                    <FormStyle.TextWrapper>
                      <CheckBoxToggle
                        aria-labelledby="agree"
                        type="NormalGrey"
                        checked={values.isDvirEnabled}
                        label={i18n.t('companyData.DVIR')+`\u00A0\u00A0  ${values.clutchSubscriptionPlan === "Monthly" ? "(Monthly - 5\u00A0 dollar/truck)" : "(Yearly - 48 dollar/truck)" }`}
                        onClick={(event) =>{
                          handleEdit(event, {
                            field: 'isDvirEnabled'
                          })
                          if(event){
                            setCModalData(featureEnable(i18n.t('companyData.DVIR')))
                          }else{
                            setCModalData(featureDisable(i18n.t('companyData.DVIR')));
                          }
                          setCModel(true);
                        }
                        }
                        name="isDvirEnabled"
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>

                  <FormStyle.FieldsWrapper
                    htmlFor="skipVehicleFilter"
                    className="agreeTerms"
                  >
                    <FormStyle.TextWrapper>
                      <CheckBoxToggle
                        aria-labelledby="agree"
                        type="NormalGrey"
                        checked={values.isIftaEnabled}
                        label={i18n.t('companyData.Ifta')+`\u00A0\u00A0  ${values.clutchSubscriptionPlan === "Monthly" ? "(Monthly - 5\u00A0 dollar/truck)" : "(Yearly - 48 dollar/truck)" }`}
                        onClick={(event) =>{
                          handleEdit(event, {
                            field: 'isIftaEnabled'
                          });
                          if(event){
                            setCModalData(featureEnable(i18n.t('companyData.Ifta')))
                          }else{
                            setCModalData(featureDisable(i18n.t('companyData.Ifta')));
                          }
                          setCModel(true);
                        }
                        }
                        name="isIftaEnabled"
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>
              </div>}
          </div>)
          }
        </Styled.rightSection>
      </Styled.wrapperSection>
      </div>
      <Styled.InsuranceCompanyWrapper>
        {!isDvirOnlyFlagEnabled && token?.roles?.includes('FLMANAGER') &&
              token?.enterpriseFeatureDto?.cycleRuleEnabled && (
                <div className="cycleruleSection">
                  <SubHeader
                    text={i18n.t('common.cycleRule')}
                    className="subheaderSection companyTitle"
                    variant="h2"
                  />
                  <FormStyle.FieldsWrapper>
                    <Label htmlFor="assignedCycleRules" variant="body1">
                      {i18n.t('common.cycleRule')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="assignedCycleRules"
                        id="assignedCycleRules"
                        onChange={(event) => {
                          if (
                            !event ||
                            !event.length ||
                            (event.length &&
                              !(
                                underscore
                                  .pluck(event, 'value')
                                  .includes('USA 60h/7 days') ||
                                underscore
                                  .pluck(event, 'value')
                                  .includes('USA 70h/8 days') ||
                                underscore
                                  .pluck(event, 'value')
                                  .includes('Texas 70h/7 day')
                              ))
                          ) {
                            handleEdit('', {
                              field: 'assignedCycleExceptions'
                            });
                          }
                          if (
                            event &&
                            event.length &&
                            (underscore
                              .pluck(event, 'value')
                              .includes('USA 60h/7 days') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('USA 70h/8 days')) &&
                            (underscore
                              .pluck(event, 'value')
                              .includes('Local Radius') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Texas 70h/7 day') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Canada 70h/7 day South') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Canada 120h/14 day South') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Canada 70h/7 day North') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Canada 120h/14 day North') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Mexico'))
                          ) {
                            handleEdit(allVehicleTypes[0], {
                              field: 'assignedVehicleType'
                            });
                          }
                          if (!event || !event.length) {
                            handleEdit(allVehicleTypes[0], {
                              field: 'assignedVehicleType'
                            });
                          }
                          handleEdit(event, { field: 'assignedCycleRules' });
                          handleEdit('', { field: 'defaultCycleRule' });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={getFilterLocaleValues(values['assignedCycleRules'], i18n)}
                        suggestions={getFilterLocaleValues(allCycleRules, i18n)}
                        isClearable
                        isMulti
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>

                  <FormStyle.FieldsWrapper>
                    <Label htmlFor="assignedCycleExceptions" variant="body1">
                      {i18n.t('companyData.exceptions')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="assignedCycleExceptions"
                        id="assignedCycleExceptions"
                        onChange={(event) => {
                          handleEdit(event, {
                            field: 'assignedCycleExceptions'
                          });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={getFilterLocaleValues(values['assignedCycleExceptions'], i18n)}
                        suggestions={getFilterLocaleValues(allCycleExceptions, i18n)}
                        isDisabled={
                          !values?.assignedCycleRules ||
                          !values?.assignedCycleRules.length ||
                          (values?.assignedCycleRules.length &&
                            !(
                              underscore
                                .pluck(values?.assignedCycleRules, 'value')
                                .includes('USA 60h/7 days') ||
                              underscore
                                .pluck(values?.assignedCycleRules, 'value')
                                .includes('USA 70h/8 days') ||
                              underscore
                                .pluck(values?.assignedCycleRules, 'value')
                                .includes('Texas 70h/7 day')
                            ))
                        }
                        isClearable
                        isMulti
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>

                  <FormStyle.FieldsWrapper>
                    <Label htmlFor="defaultCycleRule" variant="body1">
                      {i18n.t('companyData.defaultCycleRule')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="defaultCycleRule"
                        id="defaultCycleRule"
                        onChange={(event) =>
                          handleEdit(event, { field: 'defaultCycleRule' })
                        }
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={getFilterLocaleValues([values['defaultCycleRule']], i18n)}
                        suggestions={getFilterLocaleValues(values['assignedCycleRules'] || [], i18n)}
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>

                  <FormStyle.FieldsWrapper>
                    <Label htmlFor="assignedVehicleType" variant="body1">
                      {i18n.t('companyData.vehicleType')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="assignedVehicleType"
                        id="assignedVehicleType"
                        onChange={(event) =>
                          handleEdit(event, { field: 'assignedVehicleType' })
                        }
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={getFilterLocaleValues([values['assignedVehicleType']], i18n)}
                        suggestions={getFilterLocaleValues(
                          values?.assignedCycleRules &&
                          values?.assignedCycleRules.length &&
                          (underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 70h/8 days')) &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Local Radius') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Texas 70h/7 day') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 70h/7 day South') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 120h/14 day South') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 70h/7 day North') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 120h/14 day North') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Mexico')
                            ? allVehicleTypes
                            : [{ label: ' Property', value: 'Property' }], i18n)
                        }
                      />
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>
                </div>
        )}
         {(!isInsuracePortalUser() && window.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasEnabledFeature('cameraIntegrationEnabled')) ? (<div className="cycleruleSection">
              <SubHeader
                    text={i18n.t('common.configuration')}
                    className="subheaderSection companyTitle"
                    variant="h2"
                  />
              <FormStyle.FieldsWrapper className="spnocycle">
                  <Tooltip mouseEnterDelay={0.1} placement='top' overlayStyle={{ maxWidth: "150px", opacity: 1 }} overlay={<Label variant="body1">{i18n.t('common.alertToEmailInfo')}</Label>}>
                    <Label htmlFor="alertsToEmail" variant="body1">
                     <div style={{ display: 'flex' }}>
                      {i18n.t('common.emailAlerts')}
                      <InfoIcon fontSize="small" color="info" />
                     </div>
                    </Label>
                  </Tooltip>
                  <FormStyle.TextWrapper className="userForm">
                    <Field
                      name="alertsToEmail"
                      id="alertsToEmail"
                      onChange={(event) => {
                        handleEdit(event, { field: 'alertsToEmail' });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues(values['alertsToEmail'], i18n)}
                      suggestions={eventTypes}
                      isClearable
                      isMulti
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
            </div>) : (<></>)}
      </Styled.InsuranceCompanyWrapper>
      {(!hasRestrictedRole(['ADMIN']) ||
        (!hasRestrictedRole(['ADMIN']) &&
          !hasRestrictedRole(['MECHANIC']))) && (
        <div className="buttonSection">
          <FormStyle.ButtonsWrapper className="companyInfoSubmit">
            <Button
              id="companyInfoSubmit"
              type="submit"
              label={
                hasSaveApiStatus?.isLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  i18n.t('Save')
                )
              }
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => submitAction(data));
              }}
            />
          </FormStyle.ButtonsWrapper>

          <FormStyle.ButtonsWrapper>
            {serviceProvider === 'clutch' ? (
              <Button
                id="subCancelButton"
                type="submit"
                label={
                  hasCancelSubscriptionApiStatus?.isLoading ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    i18n.t('companyData.cancelSubscription')
                  )
                }
                onClick={() => {
                  setOpen(true);

                  // const validateResponse = validateForm();
                  // validateResponse.then((data) => submitAction(data));
                }}
              />
            ) : serviceProvider !== 'em' && serviceProvider !== 'tp' && serviceProvider !== 'cyntrx' && serviceProvider !== 'edvir' && serviceProvider !== 'webfleet' && serviceProvider !== 'riskTheory' && serviceProvider !== 'at' && serviceProvider !== 'eldx' && serviceProvider !== 'simplex' && serviceProvider !== 'azuga' && serviceProvider !== 'trackit' && serviceProvider !== 'ft' && serviceProvider !== 'hcss' && serviceProvider !== 'patriot' && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? (
              <Button
                id="subCancelButton"
                type="submit"
                label={i18n.t('companyData.updateCreditCardDetails')}
                onClick={() => {
                  setModalListObject({
                    content: () => {
                      return (
                        <>
                          <Payment
                            getEmail
                            ref={paymentRef}
                            buttonLabel={'Update Credit Card Details'}
                            validationMessage={i18n.t(
                              'common.fieldRequiredMessage'
                            )}
                            updateCard={(data) => cardDetails(data)}
                            handleOnChange={(event, type) => {}}
                            validationMessageMail={i18n.t(
                              'common.emailValidationMessage'
                            )}
                            hidepaymentButton
                          />
                          <div className="deletebtn companyDataCard">
                            <Button
                              label={i18n.t(
                                'companyData.updateCreditCardDetails'
                              )}
                              onClick={() => {
                                paymentRef.current.handleSubmit(() => {});
                              }}
                            ></Button>
                          </div>
                        </>
                      );
                    }
                  });
                  setOpen(true);
                }}
              />
            ) : null}
          </FormStyle.ButtonsWrapper>
        </div>
      )}
      {serviceProvider === 'clutch' && 
              <Modal.default
              open={cModal}
              setOpen={setCModel}
              data={cModalData}
              alignItemsLeft
              companyData
            />
        }
      <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        withCloseIcon
        alignItemsLeft
        companyData
      />
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
