import * as Base from './base';
import {
  fuelConsumptionReport as basFuelConsumptionReport,
  accidentsList as baseAccidentsList,
  addOrEditNewForm as baseAddOrEditNewForm,
  addOrEditNewFormTrailer as baseAddOrEditNewFormTrailer,
  addState as baseAddState,
  addUser as baseAddUser,
  addVehicle as baseAddVehicle,
  addWorkOrders as baseAddWorkOrders,
  advancedDvirEnabled as baseAdvancedDvirEnabled,
  alertViewMore as baseAlertViewMore,
  archiveLogsList as baseArchiveLogsList,
  cameraEvents as baseCameraEvents,
  cameraList as baseCameraList,
  cameraVehicleEvents as baseCameraVehicleEvents,
  companyDataEdit as baseCompanyDataEdit,
  companyDocument as baseCompanyDocument,
  companyList as baseCompanyList,
  csaViolation as baseCsaViolation,
  customDvirEnabled as baseCustomDvirEnabled,
  dailyDocuments as baseDailyDocuments,
  downloadCenter as baseDownloadCenter,
  driveHrsExceeding as baseDriveHrsExceeding,
  driverStatusHistory as baseDriverStatusHistory,
  editLog as baseEditLog,
  editLog1 as baseEditLog1,
  editState as baseEditState,
  editUser as baseEditUser,
  editVehicle as baseEditVehicle,
  editWorkOrder as baseEditWorkOrders,
  fuelEconomy as baseFuelEconomy,
  hardAcceleration as baseHardAcceleration,
  hardDeceleration as baseHardDeceleration,
  hardTurn as baseHardTurn,
  iftaList as baseIFTAList,
  inspections as baseInspections,
  logsEdit as baseLogsEdit,
  logsHistory as baseLogsHistory,
  logsView as baseLogsView,
  manageScheduling as baseManageScheduling,
  outOfService as baseOutOfService,
  partsInventoryEnabled as basePartsInventoryEnabled,
  policyEdit as basePolicyEdit,
  potentialViolationsViewMore as basePotentialViolationsViewMore,
  privacyPolicyScreen as basePrivacyPolicyScreen,
  profile as baseProfile,
  refillsHistory as baseRefillsHistory,
  reportMileage as baseReportMileage,
  reportsEnabled as baseReportsEnabled,
  resetPassword as baseResetPassword,
  seatBeltWarning as baseSeatBeltWarning,
  speeding as baseSpeeding,
  subscriptionMessage as baseSubscriptionMessage,
  unassignedInspections as baseUnassignedInspections,
  userList as baseUserList,
  vehicleList as baseVehicleList,
  vehicleNotInUse as baseVehicleNotInUse,
  vehicleStatusViewMore as baseVehicleStatusViewMore,
  workOrders as baseWorkOrders,
  usDotManagementenabled as usDotManagement
} from './base';
export const vehicleDTCView = Base.vehicleDTCView;

export const downloadCenter = baseDownloadCenter;
export const manageScheduling = baseManageScheduling;

export const privacyPolicyScreen = basePrivacyPolicyScreen;
export const archiveLogsList = baseArchiveLogsList;
export const usDotManagementenabled = usDotManagement;

export const dashboard = [
  {
    name: 'pages/web/Dashboard/OverrideComponent',
    props: ['dashboard', 'getDriverStatus', 'getVehicleStatus'],
    commonProps: ['Styled', 'i18n', 'history'],
    alternateComponent: true,
    dataList: [
      {
        id: 'overview',
        nameKey: 'dashboard.tabs.overview',
        value: null,
        dependency: '',
        icon: 'overview'
      },
      {
        id: 'radiusComplianceAlerts',
        nameKey: 'dashboard.tabs.alerts.heading',
        value: null,
        dependency: '',
        icon: 'alerts'
      },
      {
        id: 'driverStatus',
        nameKey: 'dashboard.tabs.driverStatus.heading',
        value: null,
        dependency: '',
        icon: 'driverStatus'
      },
      {
        id: 'potentialViolations',
        nameKey: 'dashboard.tabs.potentialViolations.heading',
        value: null,
        dependency: '',
        icon: 'violation'
      },
      {
        id: 'alerts',
        nameKey: 'dashboard.tabs.alerts.heading',
        value: null,
        dependency: '',
        icon: 'alerts'
      },
      {
        id: 'driverBehavior',
        nameKey: 'dashboard.tabs.driverBehavior',
        value: null,
        defaultExpanded: true,
        disabled: true,
        dependency: 'geometrisEnabled'
      },
      {
        id: 'csaViolationFieldWaitingEnabled',
        nameKey: 'dashboard.tabs.csaSnapshot',
        value: null,
        dependency: '',
        icon: 'csaSnapshot'
      },
    ],
  }
];

export const login = [
  {
    name: 'pages/web/Login/Component',
    props: ['apiStatus', 'login', 'fetchLogin'],
    commonProps: ['Styled', 'i18n', 'history'],
    hasRegistration: true,
    innerComponent: 'pages/web/Login/OverrideRenderComponent'
  }
];

export const userList = baseUserList;
export const editUser = baseEditUser;
export const addUser = baseAddUser;
export const vehicleStatusViewMore = baseVehicleStatusViewMore;
export const driverStatusViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'dashboard.tabs.driverStatus.heading'
  },
  {
    name: 'pages/web/DriverStatusViewMore/OverrideComponent',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'driverStatus',
      'driverStatusList',
      'filterSuccess',
      'driverStatusFilteredList',
      'getDriverStatus',
      'refreshDriverStatusMap',
      'changeRequest',
      'request',
      'totalElements',
      'pagination',
      'setPagination'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: true
  }
];

export const vehicleList = baseVehicleList;
export const addVehicle = baseAddVehicle;
export const editVehicle = baseEditVehicle;
export const cameraList = baseCameraList;
export const cameraEvents = baseCameraEvents;
export const cameraVehicleEvents = baseCameraVehicleEvents

export const potentialViolationsViewMore = basePotentialViolationsViewMore;

export const companyDataEdit = baseCompanyDataEdit;
export const companiesEdit = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companies.editPageHeading'
  },
  {
    name: 'pages/web/CompanyManagement/Component',
    props: [
      'apiStatus',
      'callback',
      'getCompanyData',
      'saveCompanyData',
      'states',
      'allCycleRules',
      'allCycleExceptions',
      'allVehicleTypes',
      'companyData'
    ],
    innerComponent: 'pages/web/CompanyManagement/RenderComponent',
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    insurancePortalUser: true,
    type: 'edit'
  }
];
export const addCompanies = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companyData.addPageHeading'
  },
  {
    name: 'pages/web/CompanyManagement/Component',
    props: [
      'apiStatus',
      'callback',
      'getCompanyData',
      'saveCompanyData',
      'states',
      'allCycleRules',
      'allCycleExceptions',
      'allVehicleTypes',
      'companyData'
    ],
    innerComponent: 'pages/web/CompanyManagement/RenderComponent',
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    insurancePortalUser: true,
    type: 'add'
  }
];

export const privacyPolicy = [
  {
    name: 'pages/web/PrivacyPolicy/clutchIndex',
    props: [],
    commonProps: [],
    title: 'component'
  }
];

export const recovery = [
  {
    name: 'pages/web/ForgotPassword/Component',
    props: ['history', 'requestForgotPassword', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/ForgotPassword/OverrideComponent'
  }
];
export const MFAPage = [
  {
    name: 'pages/web/MFA/Component',
    props: ['history', 'authenticteOtp', 'clearApiStatus', 'apiStatus'],
    commonProps: ['Styled', 'i18n'],
    title: 'component',
    innerComponent: 'pages/web/MFA/OverrideComponent'
  }
]

export const registration = [
  {
    name: 'pages/web/Registration/Clutch/Component',
    props: [
      'apiStatus',
      'truckInfo',
      'getTruckTypes',
      'fetchRegistration',
      'registration',
      'getTruckMake',
      'getTruckModel',
      'make',
      'addCheckout',
      'getCheckout',
      'addSigneeName',
      'getStates',
      'companyInfo',
      'addCompanyInfo',
      'getCompanyInfo',
      'addFleetManagerInfo',
      'fleetmanagerInfo',
      'getFleetManagerInfo',
      'addTruckInfo',
      'getTruckInfo',
      'pageTitle',
      'getAllConnectionType',
      'clearValues',
      'clearCompanyInfoValues',
      'clearFleetInfoValues',
      'clearTruckInfoValues'
    ],
    commonProps: ['history', 'i18n', 'Styled', 'theme'],
    title: 'component'
  }
];

export const driverStatusHistory = baseDriverStatusHistory;
export const logsView = baseLogsView;
export const logsEdit = baseLogsEdit;
export const logBook = [
  {
    name: 'pages/web/LogBook/OverrideIndex',
    props: [
      'apiStatus',
      'logBook',
      'callback',
      'handleLogBookStatus',
      'clearApiStatus',
      'unSignedDates',
      'breadCrumbForMap',
      'getLogbookEvents',
      'updateShowEvents',
      'fetchLogBookView',
      'dailyLogDownloadData',
      'regeneratePdf'
    ],
    title: 'component',

    commonProps: ['Styled', 'i18n', 'history']
  }
];
export const logsHistory = baseLogsHistory;
export const logsList = [
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: true,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'Driver Name',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      },

      {
        id: 'actions',
        label: 'Actions'
      }
    ]
  },
  {
    name: 'components/modules/LogsManagement/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'filters',
      'filterSuccess',
      'handleLogBookStatus',
      'clearApiStatus',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      export: 'exportAllPotentialViolationsData',
      list: 'potentialViolations',
      delete: 'deletePotentialViolations'
    },
    fileName: 'Violations.pdf',
    needSearch: false,
    needFilter: true,
    needExportAll: true,
    header: 'logs.manageLogsHeader',
    verticalTab: false,
    columns: [
      {
        id: 'driver_name',
        numeric: false,
        disablePadding: true,
        label: 'common.driver',
        needSorting: true
      },
      {
        id: 'vehicle_name',
        numeric: false,
        disablePadding: false,
        label: 'common.vehicle',
        needSorting: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'common.date',
        needSorting: true
      },
      {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'common.hoursWorked',
        needSorting: true
      },
      {
        id: 'distance',
        numeric: true,
        disablePadding: false,
        label: 'common.distance',
        needSorting: true
      },
      {
        id: 'cycleRule',
        numeric: false,
        disablePadding: false,
        label: 'common.cycleRule',
        needSorting: true
      }
    ]
  }
];

export const logsUdList = [
  {
    name: 'pages/web/UndefinedDriverList/OverrideComponent',
    props: [
      'apiStatus',
      'udLogList',
      'unAssignedEntries',
      'fetchUdLogList',
      'fetchUnAssignedEntries',
      'clearUdAssignEntryListById',
      'setUdAssignEntryList',
      'saveUnAssignedEntries',
      'callback',
      'udFilterSuccess',
      'filters',
      'clearAll'
    ],
    commonProps: ['history', 'i18n', 'Styled'],
    title: 'component',
    verticalTab: true
  }
];

export const reportsEnabled = baseReportsEnabled;
export const resetPassword = baseResetPassword;
export const profile = baseProfile;
export const unhandled = [
  {
    name: 'pages/web/Unhandled/OverrideComponent',
    props: [],
    commonProps: ['Styled', 'i18n', 'history']
  }
];
export const subscriptionMessage = baseSubscriptionMessage;
export const editLog = baseEditLog;
export const editLog1 = baseEditLog1;
export const policyEdit = basePolicyEdit;
export const advancedDvirEnabled = baseAdvancedDvirEnabled;
export const reportMileage = baseReportMileage;
export const iftaList = baseIFTAList;
export const addState = baseAddState;
export const editState = baseEditState;
export const departmentsList = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.departments'
  },
  {
    name: 'pages/web/Department/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchTerminals',
      'data',
      'saveRegion',
      'updateRegion',
      'deleteRegion',
      'saveTerminal',
      'deleteTerminal',
      'updateTerminal',
      'saveDepartment',
      'updateDepartment',
      'deleteDepartment',
      'saveSubDepartment',
      'updateSubDepartment',
      'deleteSubDepartment',
      'setAddEditModalObject',
      'clickedAddEditModal',
      'closeAddEditModal',

      'isTermCrudRestricted',
      'getTmezonePopup',
      'isCrudRestricted',
      'isDeptCrudRestricted',
      'isLocCrudRestricted'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    topAction: true,
    isButtonStyle: false,
    title: 'component',
    isShowDotNumber: false
  }
];

export const pendingDriverInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/PendingDriverInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const completedInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/CompletedInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];


export const pendingMechanicInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/PendingMechanicInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const noDefectInspections = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'inspections.listHeading'
  },
  {
    name: 'pages/web/NoDefectInspections/Component',
    props: [
      'apiStatus',
      'callback',
      'fetchInspections',
      'bulkDownloadReport',
      'inspections',
      'list',
      'changeRequest',
      'isAdvancedDviRequired',
      'isCustomDviRequired',
      'logBook',
      'clearApiStatus',
      'getDocumentToken',
      'filters',
      'viewReport',
      'request',
      'setPagination',
      'pagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    type: 'inspections',
    topAction: true
  }
];

export const fleetCompanies = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'companies.listHeading'
  },
  {
    name: 'pages/web/FleetCompanies/Component',
    topAction: true,
    props: [
      'apiStatus',
      'fleetCompanies',
      'filterSuccess',
      'fetchFleetCompanies',
      'callback',
      'filters',
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',

    columns: [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'companies.companyName',
        needSorting: true
      },
      {
        id: 'dotNumber',
        numeric: false,
        disablePadding: false,
        label: 'companies.policyHolderNumber',
        needSorting: true
      },
      {
        id: 'activeTrucksNumber',
        numeric: false,
        disablePadding: false,
        label: 'companies.activeVehicles',
        needSorting: true
      },

      // {
      //   id: 'severityGraph',
      //   numeric: false,
      //   disablePadding: false,
      //   label: 'companies.severity',
      //   needSorting: true
      // },
      {
          id: 'fleetScoreEle',
          numeric: true,
          disablePadding: false,
          label: 'companies.fleetScore',
          toolTip: "out of 100",
          needSorting: true,
          sort_id: 'fleetScore'
      },
      {
        id: 'lossControl',
        numeric: true,
        disablePadding: false,
        label: 'companies.lossControl',
        needSorting: true
      },
      {
        id: 'insuranceExpDate',
        numeric: true,
        disablePadding: false,
        label: 'companies.xDate',
        needSorting: true
      },
      {
        id: 'alerts',
        numeric: false,
        disablePadding: false,
        label: 'companies.alerts',
        needSorting: false
      },
      {
        id: 'actions',
        label: 'Actions',
      }
    ]
  }
];

export const inspections = baseInspections;
export const csaViolation = baseCsaViolation;
export const fuelConsumptionReport = basFuelConsumptionReport;
export const refillsHistory = baseRefillsHistory;
export const hardAcceleration = baseHardAcceleration;
export const hardDeceleration = baseHardDeceleration;
export const speeding = baseSpeeding;
export const driveHrsExceeding = baseDriveHrsExceeding;
export const fuelEconomy = baseFuelEconomy;
export const seatBeltWarning = baseSeatBeltWarning;
export const hardTurn = baseHardTurn
export const companyDocument = baseCompanyDocument;
export const editWorkOrder = baseEditWorkOrders
export const workOrders = baseWorkOrders;
export const addWorkOrders = baseAddWorkOrders;
export const partsInventoryEnabled = basePartsInventoryEnabled;
export const vehicleNotInUse = baseVehicleNotInUse;
export const outOfService = baseOutOfService;
export const unassignedInspections = baseUnassignedInspections;
export const companyList = baseCompanyList;
export const dailyDocuments = baseDailyDocuments;
export const accidentsList = baseAccidentsList;
export const alertViewMore = baseAlertViewMore;

export const customDvirEnabled = baseCustomDvirEnabled;
export const addOrEditNewForm = baseAddOrEditNewForm;
export const addOrEditNewFormTrailer = baseAddOrEditNewFormTrailer;
export const scoreCard = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'scoreCard.heading'
  },
  {
    name: 'pages/web/Scorecard/Component',
    props: [
      'apiStatus',
      'getVehicles',
      'hasFetchApiStatus',
      'fetchDetails',
      'fetchRadiusDetails',
      'fetchQuickStatsDetails',
      'scoreCard',
      'radiusData'
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: true
  }
];

export const newScoreCard = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'newScoreCard.heading'
  },
  {
    name: 'pages/web/NewScorecard/Component',
    props: [
      'apiStatus',
      'scoreCard',
    ],
    commonProps: ['Styled', 'i18n', 'history', 'theme'],
    title: 'component',
    topAction: true,
    mapRequired: true
  }
];

export const scorecardReport = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Scorecard Report'
  },
  {
    name: 'pages/web/ScorecardReport/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'callback',
      'filters',
      'vehicles',
      'paginateList',
      'changeRequest',
      'request',
      'pagination',
      'setPagination',
      'downloadDocument',
      'companyId',
      'chartsData'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
  }
]

export const quickStats = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'Quick Stats'
  },
  {
    name: 'pages/web/QuickStats/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'callback',
      'filters',
      'paginateList',
      'changeRequest',
      'request',
      'pagination',
      'setPagination'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
  }
]

export const fleetScoreCard = [scoreCard[0], { ...scoreCard[1], isFleetScoreCard: true }];
export const radiusComplianceAlertsViewMore = [
  {
    name: 'components/Title',
    props: [],
    commonProps: ['i18n'],
    title: 'title',
    header: 'common.alerts'
  },
  {
    name: 'pages/web/RadiusComplianceAlerts/Component',
    props: [
      'apiStatus',
      'hasFetchApiStatus',
      'list',
      'callback',
      'columns',
      'filterSuccess',
      'pagination',
      'setPagination',
      'fetchFleetCompanies',
      'fleetCompanies',
      'selectedCompany'
    ],
    commonProps: ['i18n', 'history', 'theme', 'Styled'],
    title: 'component',
    apiIds: {
      list: 'alertList',
    },
    needFilter: true,
    topAction: true,
  }
];
